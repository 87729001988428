import classes from './Banner.module.css';


const Banner=()=>{
    return <section className={classes.main}> 
            
             
               
                <h1>About Us</h1>
              
             

    </section>
};


export default Banner;