import Corepins from  "../Components/Product/Corepins";
import Ttc from "../Components/Product/Ttc";
import Gradingw from "../Components/Product/Gradingw";
import Cdc from "../Components/Product/Cdc";
import Vsa from "../Components/Product/Vsa";
import Hfa from "../Components/Product/Hfa";
import Pc from "../Components/Product/Pc";
import Restpad from "../Components/Product/Restpad";
import Industrical from "../Components/Product/Industrialc";

import React from 'react';

export const Product = () => {
  return (
    <div>
       <Corepins></Corepins>
       <Ttc></Ttc>
       <Gradingw></Gradingw>
       <Cdc></Cdc>
       <Vsa></Vsa>
       <Hfa></Hfa>
       <Pc></Pc>
       <Restpad></Restpad>
       <Industrical></Industrical>
       
    </div>
  )
}
export default Product